<template>
    <div>

        <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>

        <div v-if="!cargando">

            <div class="row">
            
                <div class="col-md-12">

                        <div class="media mb-4">
                            <img class="img-fluid mr-3 img-thumbnail mb-1" style="width: 100px;" :src="archivospublicos+'/personas/'+infopersona.foto" alt="">
                            <div class="media-body">
                                <h5 class="mt-0 text-capitalize"> {{infopersona.nombres}} {{infopersona.apellidos}} </h5>

                                <router-link :to="{ name: 'Editarpersona', params: { id: infopersona.idpersonas }}" class="btn btn-light btn-sm"  title="Editar Información" > 
                                    <i class="fas fa-pencil-alt"></i> Editar
                                </router-link>

                                <button type="button" class="btn btn-sm btn-light" data-toggle="modal" data-target="#modalcargarfoto" title="Actualizar foto de perfil">
                                    <i class="fas fa-photo-video"></i> Actualizar Foto
                                </button>
                            </div>
                            Estado 
                            <div  v-if="infopersona.recepcion==1" class="ml-2 pl-3 pr-3 pb-1 mb-2 bg-success text-white text-center rounded-pill" style="width:80px">Activo</div>
                            <div  v-if="infopersona.recepcion==0" class="ml-2 pl-3 pr-3 pb-1 mb-2 bg-secondary text-white text-center rounded-pill" style="width:80px">Activo</div>
                         </div>

 
          

                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#dp" role="tab" aria-controls="dp" aria-selected="true">Datos Personales</a>
                            </li>
                            <li class="nav-item" role="presentation">
                            <a class="nav-link" id="laboral-tab" data-toggle="tab" href="#laboral" role="tab" aria-controls="laboral" aria-selected="false">Laboral</a>
                            </li>
                            <li class="nav-item" role="presentation">
                            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                            </li>
                      </ul>

                      <div class="tab-content mt-3" id="myTabContent">

                            <div class="tab-pane fade show active" id="dp" role="tabpanel" aria-labelledby="dp-tab">
                                    <div class="row">
                                        <div class="col-md-6 ">
                                            <div class="card card-body">
                                                <strong>Datos Personales</strong>
                                                <hr>
                                                <table class="table table-sm table-borderless">
                                                    <tr>
                                                        <td style="width: 150px;">Identificación:</td>
                                                        <td>{{infopersona.identificacion}}</td>
                                                    </tr>
                                                <tr>
                                                    <td style="width: 150px;">Tipo de Documento:</td>
                                                    <td> {{tipodocumento[infopersona.tipoidentificacion]}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 150px;">Fecha de Expedición:</td>
                                                    <td> {{infopersona.fechaexpedicion}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 150px;">Lugar de Expedición:</td>
                                                    <td>{{infopersona.ciudadexpedicion.nombreciudad}} /
                                                        {{infopersona.ciudadexpedicion.nombredepartamento}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 150px;">Fecha Nacimiento:</td>
                                                    <td>{{infopersona.fechanacimiento}} /
                                                        <strong>{{  edad(infopersona.fechanacimiento ) }} </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 150px;">Lugar Nacimiento:</td>
                                                    <td>{{infopersona.ciudadnacimiento.nombreciudad}} /
                                                        {{infopersona.ciudadnacimiento.nombredepartamento}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 150px;">Sexo:</td>
                                                    <td> <span v-if="infopersona.genero=='H'">Hombre</span> <span
                                                            v-if="infopersona.genero=='M'">Mujer</span> </td>
                                                </tr>
                                                </table>
                                            </div>
                                            
                                            <div class="card card-body mt-2">
                                                <strong>Otros Datos</strong>
                                                <hr>
                                                <table class="table table-sm table-borderless">
                                                    <tr>
                                                        <td style=" width: 150px;">Tipo de Sangre:</td>
                                                        <td class="text-uppercase">{{infopersona.tiposangre}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 150px;">Estado Civil:</td>
                                                        <td>
                                                            {{ estadocivil[infopersona.estadocivil] }}
    
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 150px;">Composición Familiar:</td>
                                                        <td>{{infopersona.composicionfamiliar}}</td>
                                                    </tr>
                                                </table>
                                            </div>

                                            <div class="card card-body mt-2">
                                                <strong>Observaciones:</strong> <br>
                                                {{infopersona.observacion}}
                                            </div>

                                            
                                            <div class="text-black-50 mt-2">
                                                    Fecha de Registro:  {{ formatoFecha(infopersona.created_at) }} <br>
                                                    Última Actualización:  {{ formatoFecha(infopersona.updated_at) }}
                                            </div>
       
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card card-body">
                                                <strong>Datos de Contacto</strong>
                                                <hr>
                                                <table class="table table-sm table-borderless">

                                                    <tr>
                                                        <td style="width: 150px;">Teléfono 1:</td>
                                                        <td>
                                                            {{infopersona.celular}}
                                                            <a title="escribir" :href="'https://wa.me/57'+infopersona.celular"
                                                                target="_blank" class="ml-2"> <i
                                                                    class="fab fa-whatsapp text-success fa-lg "></i> </a>
                                                            <a title="llamar" :href="'tel:'+infopersona.celular" class="ml-2"> <i
                                                                    class="fas fa-mobile-alt fa-lg text-black"></i> </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 150px;">Teléfono 2:</td>
                                                        <td>
                                                            {{infopersona.telefono}}
                                                            <a title="llamar" :href="'tel:'+infopersona.telefono" class="ml-2"> <i
                                                                    class="fas fa-mobile-alt fa-lg text-black"></i> </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 150px;">Email:</td>
                                                        <td>{{infopersona.email}}</td>
                                                    </tr>
    <hr>
                                                    <tr>
                                                        <td style="width: 150px;">Dirección:</td>
                                                        <td>{{infopersona.direccion}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 150px;">Barrio:</td>
                                                        <td> {{infopersona.barrio}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ciudad:</td>
                                                        <td>{{infopersona.ciudadresidencia.nombreciudad}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Depto: </td>
                                                        <td>{{infopersona.ciudadresidencia.nombredepartamento}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Estrato:</td>
                                                        <td>{{infopersona.estrato}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="card card-body mt-2">
                                                Firma:
                                                <span v-if="infopersona.autorizacionfirma==1" class="float-right">Autorizado <i style="font-size: 20px;" class="fas fa-toggle-on pr-1 text-success"></i> </span>
                                                <span v-if="infopersona.autorizacionfirma==0" class="float-right"> Sin Autorización <i style="font-size: 20px;" class="fas fa-toggle-on pr-1 text-secondary"></i></span>

                                                <p></p>
                                                <div class="card mt-2 p-2 mb-2  aling-center position-relative" style="min-height: 150px;">
                                                    <span class="text-secondary">Firma</span>
                                                    <img src="/images/seguridadfirma.png" class="position-absolute img-fluid" alt=""
                                                        style="top: 0px;left:0px;">
                                                    <img v-if="infopersona.firma" :src="'/storage/personas/firmas/'+infopersona.firma"
                                                        class="img-fluid" alt="">
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                            </div>

                            <div class="tab-pane fade" id="laboral" role="tabpanel" aria-labelledby="laboral-tab">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <strong>Recepción</strong> <br>
                                            Recepción de documentación y PQRS <br>
                                            <span v-if="infopersona.recepcion==1">Activa <i style="font-size: 20px;" class="fas fa-toggle-on pr-1 text-success"></i> </span>
                                            <span v-if="infopersona.recepcion==0">Inactiva <i style="font-size: 20px;" class="fas fa-toggle-on pr-1 text-secondary"></i> </span>
                                        </div>
                                    </div>
                            </div>


                            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>

                      </div>
                </div>
  
  
            </div>
        </div> <!-- fin div carga-->



        <!-- Modal -->
        <div class="modal fade" id="modalcargarfoto" tabindex="-1" role="dialog" aria-labelledby="modalcargarfotoLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="card-body">
                        <form v-on:submit.prevent="updatefoto">
                            <p class="text-center">
                                <img src="/imagenes/ico/foto.svg" width="100px">
                                <br>
                                Actualizar La Foto de Perfil <br>
                            </p>
                            <hr>
                            <input type="file" name="image" @change="getImage" accept="image/*" required>
                            <br>
                            <button type="submit" class="btn btn-sm btn-info mt-2"><i class="fas fa-upload"></i>
                                Cargar</button>
                        </form>

                        <div class="mt-2 alert alert-success" v-if="respuestacarga">
                            {{respuestacarga}}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: "infopersonal",
        data() {
            return {
                infopersona: [],
                estadocivil: ['Soltero/a', 'Casado/a', 'En Relación ( más de 1 Año de noviazgo)', 'Comprometido/a', 'Unión libre o unión de hecho', 'Separado/a', 'Divorciado/a', 'Viudo/a.'],
                tipodocumento: ['-', 'Cédula de Ciudadanía', 'Cédula de Extrangería', 'Pasaporte', 'Permiso Especial de Trabajo'],
                cargando: true,
                imagen: null,
                respuestacarga: null
            }
        },
        methods: {
            edad: function (date) {
                return moment(new Date()).diff(date, 'years') + ' Años'
            },
            formatoFecha: function (date) {
                return moment(date).format('MMMM D [de] YYYY, h:mm:ss a');
            },
            consultarinformacion: function () {

                axios.get('/api/infopersona/'+this.$route.params.id+'/3', { headers: { Authorization: "Bearer " + localStorage.getItem("token"), } })
                    .then(response => {
                        this.cargando = false;
                        this.infopersona = response.data;
                    });
            },            
            getImage(event) {
                //Asignamos la imagen a  nuestra data
                this.imagen = event.target.files[0];
                this.respuestacarga = "";

            },
            updatefoto: function () {
                var data = new FormData();
                data.append('foto', this.imagen);
                data.append('fotoanterior', this.infopersona.foto);
                data.append('_method', 'PUT');
                data.append('id', this.infopersona.idpersonas);
                axios.post('/api/cambiarimagen', data, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
                    .then(response => {
                        this.infopersona.foto = response.data;
                        this.respuestacarga = "Imagen cargada Correctamente";
                    })
            }

        },
        mounted() {
            this.consultarinformacion();
        }
    }
</script>